import React from 'react';

const SvgTeam = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="622.721" height="372.981" viewBox="0 0 622.721 372.981" {...props}>
    <g id="Group_398" data-name="Group 398" transform="translate(29.041 -173.806)">
      <g id="OBJEKTS" transform="translate(-29.041 173.806)">
        <g id="Group_420" data-name="Group 420" transform="translate(0 0)">
          <path
            id="Path_916"
            data-name="Path 916"
            d="M282.1,374.913a21.933,21.933,0,0,0,3.48-.275c4.95-.713,9.92.23,14.867.475a67.312,67.312,0,0,0,7.182-.379c1.883-.1,3.668.049,5.259-1.26a3.033,3.033,0,0,0,1.133-3.069c-.472-1.587-2.374-2.168-4-2.5l-11.878-2.45c-1.6-.331-4.7-1.009-5.057-2.988-.175-.958.379-2.569.471-3.576q.174-1.88.257-3.769c.1-2.284.831-4.514-2.05-4.67l-9.239-.5a1.758,1.758,0,0,0-.862.1,1.782,1.782,0,0,0-.755,1.279l-1.263,5.092c-.654,2.636-1.309,5.277-1.723,7.964-.3,1.909-.26,3.834-.445,5.75-.112,1.175-.43,2.346.433,3.324A5.619,5.619,0,0,0,282.1,374.913Z"
            transform="translate(79.887 -79.013)"
          />
          <path
            id="Path_917"
            data-name="Path 917"
            d="M293.923,380.047a2.319,2.319,0,0,0,1.138,1.712c1.232.619,3.337.508,4.688.747a25.83,25.83,0,0,0,6.742.436c1.673-.148,3.322-.529,5-.6,4.777-.2,9.657,1.633,14.373.279,1.626-.466,4.376-1.517,4.866-3.373.644-2.441-1.529-3.979-3.547-4.541a32.821,32.821,0,0,0-8-1.123,16.286,16.286,0,0,1-3.262-.258c-2.712-.624-3.772-2.814-3.163-5.329.812-3.353,1.587-6.715,2.379-10.073-5.522-.762-11.122.163-16.62,1.086a1.18,1.18,0,0,0-1.245,1.091c-.874,3.66-.426,7.108-1.656,10.615C295.025,372.409,293.341,377.3,293.923,380.047Z"
            transform="translate(91.057 -73.793)"
          />
          <g id="Group_404" data-name="Group 404" transform="translate(123.3 24.512)">
            <g id="Group_403" data-name="Group 403">
              <path
                id="Path_918"
                data-name="Path 918"
                d="M206.211,248.684a30.47,30.47,0,0,1,3.292-.805,9.413,9.413,0,0,0,4.8-2.94,10.762,10.762,0,0,1,3.121-2.4c3.868-1.661,5.817,1.7,4.4,5.027-1.112,2.6-2.891,5.287-5.48,6.593-2.859,1.441-5.787,1.534-8.358,3.579-.653.52-7.464,6.245-7.18,6.6-1.277-1.586-4.042-5.039-3.889-7.18a7.929,7.929,0,0,0,4.3-3.512c1.07-1.581,2.05-3.322,3.671-4.329A6.687,6.687,0,0,1,206.211,248.684Z"
                transform="translate(-97.936 -176.641)"
                fill="#fff"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <g id="Group_401" data-name="Group 401">
                <g id="Group_398-2" data-name="Group 398" transform="translate(0 74.83)">
                  <rect id="Rectangle_81" data-name="Rectangle 81" width="2.938" height="64.799" transform="translate(47.717 84.368)" fill="#110809" />
                  <path id="Path_919" data-name="Path 919" d="M151.929,350.178c0-9.568,16.931-13.58,25.391-13.6,10.071-.02,25.391,3.693,25.391,13.6" transform="translate(-128.428 -187.414)" fill="none" stroke="#110809" strokeMiterlimit="10" strokeWidth="2" />
                  <line id="Line_9" data-name="Line 9" y1="15.893" transform="translate(49.177 149.429)" fill="none" stroke="#110809" strokeMiterlimit="10" strokeWidth="2" />
                  <path id="Path_920" data-name="Path 920" d="M188.026,347.739a4.155,4.155,0,1,1-4.155-4.155A4.153,4.153,0,0,1,188.026,347.739Z" transform="translate(-109.589 -182.666)" fill="#110809" />
                  <path id="Path_921" data-name="Path 921" d="M173.019,347.739a4.155,4.155,0,1,1-4.155-4.155A4.153,4.153,0,0,1,173.019,347.739Z" transform="translate(-119.763 -182.666)" fill="#110809" />
                  <path id="Path_922" data-name="Path 922" d="M158.012,347.739a4.155,4.155,0,1,1-4.155-4.155A4.153,4.153,0,0,1,158.012,347.739Z" transform="translate(-129.937 -182.666)" fill="#110809" />
                  <path
                    id="Path_923"
                    data-name="Path 923"
                    d="M184.256,310.3V262.624a14.941,14.941,0,0,0-14.941-14.941H152.864a14.941,14.941,0,0,0-14.941,14.941v53.3a21.467,21.467,0,0,0,21.4,21.4h53.636a2.518,2.518,0,0,0,2.517-2.519h0a2.518,2.518,0,0,0-2.517-2.517h-6.715A21.991,21.991,0,0,1,184.256,310.3Z"
                    transform="translate(-137.923 -247.683)"
                    fill="#fff"
                    stroke="#000"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                  />
                </g>
                <g id="Group_400" data-name="Group 400" transform="translate(4.044)">
                  <g id="Group_399" data-name="Group 399">
                    <path
                      id="Path_924"
                      data-name="Path 924"
                      d="M233.171,341.885a23.364,23.364,0,0,0,1.282,2.081c1.49,2.118,4.747,3.987,7.42,3.634,2.878-.381,5.695-1.4,8.594-1.258a4.483,4.483,0,0,1,2.957,1.04c3.349,3.264-4.633,6.183-6.831,6.843q-7.5,2.245-14.991,4.492a6.268,6.268,0,0,1-2.881.389c-2.049-.294-2.928-1.663-3.494-3.384-.718-2.19-1.579-4.292-2.447-6.453a68.188,68.188,0,0,1-3.316-10.762,6.337,6.337,0,0,1-.133-3.109,3.929,3.929,0,0,1,3.524-2.722,6.807,6.807,0,0,1,2.007.416C229.487,334.551,230.923,337.847,233.171,341.885Z"
                      transform="translate(-86.874 -115.232)"
                      fill="#fff"
                      stroke="#000"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_925"
                      data-name="Path 925"
                      d="M243.778,301.782a25.49,25.49,0,0,0-1.477-9.729c-1.816-4.864-5.589-9.128-10.5-10.816a29.83,29.83,0,0,0-8.692-1.171l-38.034-1.344c-11.027-.389-23.772,8.548-23.434,20.656.208,7.485,5.316,15.261,13.388,15.508.178.005.352-.013.53-.008q18.067.1,36.133.2c8.464.047,19.5,3.556,27.151-1.114a9.025,9.025,0,0,0,2.782-2.467,10.726,10.726,0,0,0,1.492-4.082A34.617,34.617,0,0,0,243.778,301.782Z"
                      transform="translate(-125.891 -151.818)"
                    />
                    <path id="Path_926" data-name="Path 926" d="M205.624,303.345c11.628,6.321-9.818,17.192-20.991,17.228l-19.421.03c-11.246,0-19.964-5.636-20.318-16.729V270.318l43.056.1S192.989,296.477,205.624,303.345Z" transform="translate(-137.241 -157.508)" />
                    <path
                      id="Path_927"
                      data-name="Path 927"
                      d="M247.613,301.768c-9.037-6.026-45.516-14.875-50.486-16.832a63.277,63.277,0,0,0-31.215-4.106c-8.922,1.176-18.276,2.685-20.491,12.751A16.714,16.714,0,0,0,158.154,313.5c.2.045.408.065.611.111l83.785,16.545c.185.05.364.111.554.153a13.656,13.656,0,0,0,1.349.223c6.579.747,11.877-3.082,13.33-9.69C259.374,313.606,255.241,306.852,247.613,301.768Z"
                      transform="translate(-137.151 -150.713)"
                    />
                    <path
                      id="Path_928"
                      data-name="Path 928"
                      d="M205.333,344.111c.034,2.512.383,4.717,2.621,6.844,3.29,3.126,8.391,3.569,11.929,6.413a2.61,2.61,0,0,1,.8.916,2.006,2.006,0,0,1-.589,2.168c-3.106,2.965-10.241.061-13.86-.584l-13.665-2.436a2.188,2.188,0,0,1-1.769-2.589l2.624-12.986.374-1.923a5.788,5.788,0,0,1,6.912-4.551,6.1,6.1,0,0,1,4.638,6.029C205.347,342.354,205.321,343.25,205.333,344.111Z"
                      transform="translate(-106.149 -113.486)"
                      fill="#fff"
                      stroke="#000"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_929"
                      data-name="Path 929"
                      d="M172.91,250.935q-.539-2.821-1.076-5.641,2.054,12.227,3.925,24.485c1.176,7.71,1.943,15.546,4.421,22.981.815,2.519,2.045,6.98,4.895,8,3.614,1.3,6,.755,9.635.794a17.29,17.29,0,0,0,4.655-.373c4.212-1.126,6.915-5.178,8.762-9.126a23.606,23.606,0,0,1,8.046-10.145q4.105-3,7.9-6.4c.93-.832,3.269-2.322,3.7-3.483.487-1.312-.312-1.777-1.258-2.742-3.046-3.1-6.411-5.935-9.277-9.207q-4.264,3.944-8.526,7.89-3.035,2.809-6.067,5.616c-.973.9-6.323,4.485-6.247,5.375-.54-6.383-1.1-12.85-3.282-18.872a9.067,9.067,0,0,1-.6-2.118,9.663,9.663,0,0,1,.342-2.9,24.367,24.367,0,0,0-1.918-15.024c-2.317-4.928-5.23-7.648-10.313-9.318a56.721,56.721,0,0,0-9.336-2.488c-.535-.079-1.258-.022-1.379.505a1.412,1.412,0,0,0,.07.626m5.188,29.732c.153-.025.324-.054.535-.091Zm-.52.087.183-.03Z"
                      transform="translate(-120.296 -186.055)"
                      fill="#fff"
                      stroke="#000"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_930"
                      data-name="Path 930"
                      d="M252.926,366.786c-7.373-15.278-12.662-31.28-18.359-47.238-2.727-7.641-5.544-15.494-10.561-21.979-2.326-3.007-4.448-5.328-8.3-6.156a24.707,24.707,0,0,0-10.529.435c-1.908.431-3.611,1.44-5.5,1.863-2.547.572-6.485-.035-7.5,3.17a8.555,8.555,0,0,0,.151,4.477,44.221,44.221,0,0,0,2.3,7.106c1.242,2.958,2.807,5.784,3.849,8.818.8,2.311,1.28,4.727,2.2,6.99a21.054,21.054,0,0,0,7.262,9.042c1.933,1.383,4.138,2.519,5.5,4.467,1,1.428,1.087,2.9,1.675,4.45,1.539,4.064,3.3,8.41,6.98,10.72a10.494,10.494,0,0,1,2.792,1.968,7.025,7.025,0,0,1,1.024,2.1,21.869,21.869,0,0,0,3.111,6.309c1.317,1.67,3.059,2.941,4.395,4.589,1.411,1.738,1.758,3.752,2.732,5.71C236.445,374.192,253.026,366.994,252.926,366.786Z"
                      transform="translate(-105.343 -143.473)"
                    />
                    <path
                      id="Path_931"
                      data-name="Path 931"
                      d="M176.617,228.316q-3.269.335-6.539.673c-6.94.715-14.308,1.6-19.734,5.984-6.453,5.213-10.382,14.5-8.984,22.708.768,4.512,2.193,8.913,2.683,13.474q2.446,22.781,3.581,45.676c7.376-.342,14.766-.4,22.147-.789,9.049-.475,19.241-.873,27.921-4.158,1.012-12.581,4.426-25.012,4.232-37.643l-.287-18.518"
                      transform="translate(-139.828 -185.983)"
                      fill="#fff"
                      stroke="#000"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_932"
                      data-name="Path 932"
                      d="M221.834,340.868q.257-.544.5-1.094a14.95,14.95,0,0,0,.775-2.269c2.381-9.031,5.532-18.82,4.554-28.567-.854-8.514-.784-19.345-9.056-24.527-6.871-4.3-16.439-7.118-22.4.018a13.731,13.731,0,0,0-2.621,5.19c-3.636,13.214,2.441,24.6.7,36.977-.44,3.109-.876,6.291-.245,9.366.21,1.022.535,2.022.673,3.056.336,2.539-.483,5.067-1.154,7.537a26.451,26.451,0,0,0-1.154,8.042c.123,2.79.96,5.52.99,8.314a28.74,28.74,0,0,1-.8,6.084c-.53,2.589-3.061,7.831.307,8.9,2.067.654,4.47.523,6.609,1.081.7.183,5.886,1.517,8.544,2.421a1.288,1.288,0,0,0,1.686-1.082c.485-3.673,2.25-7.536,3.215-11q1.915-6.871,3.562-13.813c.527-2.222,1.034-4.453,1.74-6.625A68.556,68.556,0,0,1,221.834,340.868Z"
                      transform="translate(-105.857 -150.897)"
                    />
                    <path id="Path_933" data-name="Path 933" d="M181.286,224.229l-2.6-15.768-10.165,2.364a11.99,11.99,0,0,0-8.96,14.4l.9,3.854a10.637,10.637,0,0,0,1.977,4.131l-2.044,9.561a8.089,8.089,0,1,0,15.9,2.871l1.616-10.935A10.689,10.689,0,0,0,181.286,224.229Z" transform="translate(-127.511 -199.444)" fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
                    <path
                      id="Path_934"
                      data-name="Path 934"
                      d="M177.918,268c4.794,1.055,8.266,3,12.5,5.27,3.646,1.956,4.356,5.561-.49,6.435-2.639.478-5.358.953-7.979.391-2.749-.589-3.537-2.175-5.307-3.95-1.5-1.507-4.071-.768-6.252-1.72a3.421,3.421,0,0,1-2.007-1.8,3.89,3.89,0,0,1,.332-2.644,5.386,5.386,0,0,1,1.235-2.091c1.19-1.094,3.035-1.005,4.608-.638C175.666,267.517,176.792,267.754,177.918,268Z"
                      transform="translate(-121.401 -159.751)"
                      fill="#fff"
                      stroke="#000"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_935"
                      data-name="Path 935"
                      d="M180.7,214.712a13.158,13.158,0,0,1-5.831,2.519,43.471,43.471,0,0,0-5.824.916c-5.052,1.6-4.368,7.173-3.933,11.437.154,1.5-1.2,6.554-3.285,5.752-1.368,0-1.594-2.039-1.913-2.916-.619-1.693-1.316-3.363-1.972-5.044a8.42,8.42,0,0,1-.824-3.641c.141-1.827,1.445-3.366,1.834-5.156.3-1.379.037-2.826.263-4.217a7,7,0,0,1,3.113-4.606c2.368-1.559,5.165-.95,7.556-2.259a33.362,33.362,0,0,0,3.478-2.819,8.315,8.315,0,0,1,6.373-1.455,5.708,5.708,0,0,1,4.393,3.292,6.155,6.155,0,0,1-.708,5.067A10.453,10.453,0,0,1,180.7,214.712Z"
                      transform="translate(-128.964 -203.087)"
                    />
                    <path id="Path_936" data-name="Path 936" d="M165.838,218.805a3.377,3.377,0,1,1-4.056-2.525A3.377,3.377,0,0,1,165.838,218.805Z" transform="translate(-127.562 -194.203)" fill="#fff" />
                    <path
                      id="Path_937"
                      data-name="Path 937"
                      d="M141.86,241.537c-.812,6.861-.956,13.8-1.361,20.711a66.447,66.447,0,0,0,2.029,22.451,16.8,16.8,0,0,0,3.072,6.37c5.621,6.549,19.479,3.854,27.116,3.851l18.88-.007c.094-.294.173-.592.269-.884.5-2.584,2.173-8.7,2.633-11.306l-11.977-.978-10.345-.846c-2.772-.227-7,.416-7.465-3.255-.164-1.29.023-2.655-.488-3.849-.76-1.779-2.9-2.767-3.4-4.634-.376-1.4.255-3.131.352-4.556a18.726,18.726,0,0,1,1.977-6.012,46.955,46.955,0,0,0,3.134-10.407l-.6-.039"
                      transform="translate(-140.333 -177.02)"
                      fill="#fff"
                      stroke="#000"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_402" data-name="Group 402" transform="translate(69.42 73.83)">
                <path id="Path_938" data-name="Path 938" d="M252.449,273.982H179.3a2.557,2.557,0,0,1,2.557-2.557h70.6Z" transform="translate(-179.295 -230.587)" fill="#003bff" />
                <path id="Path_939" data-name="Path 939" d="M259.917,287.925h-73.1l13.036-38.608a3.42,3.42,0,0,1,3.284-2.23H269.03a3.229,3.229,0,0,1,3.285,4.119Z" transform="translate(-174.195 -247.087)" fill="#003bff" />
                <path id="Path_940" data-name="Path 940" d="M258.784,275.338H186.608v-2.557l73.451-3.356v4.638A1.277,1.277,0,0,1,258.784,275.338Z" transform="translate(-174.337 -231.943)" fill="#003bff" />
                <path id="Path_941" data-name="Path 941" d="M213.744,261.424c-1.426-.06-2.779-1.215-1.817-2.893a2.456,2.456,0,0,1,2.064-1.109.629.629,0,0,0,.116-.017.606.606,0,0,0,.138.055A2,2,0,0,1,213.744,261.424Z" transform="translate(-157.391 -240.092)" fill="#c9cdd1" />
              </g>
              <path id="Path_942" data-name="Path 942" d="M186.8,258.253a5.307,5.307,0,0,1-.9,1.46,15.144,15.144,0,0,0-.953,1.453,11.049,11.049,0,0,0-.71,1.084" transform="translate(-106.521 -165.687)" fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
            </g>
            <path id="Path_943" data-name="Path 943" d="M165.52,224.229c2.223,2.77,5.2,4.895,7.207,7.829l.71-5.625a28.369,28.369,0,0,1,.7-2.908C171.145,224.219,168.649,225,165.52,224.229Z" transform="translate(-119.214 -189.23)" />
            <path id="Path_944" data-name="Path 944" d="M167.6,220.361a2.349,2.349,0,0,0,1.834,1.963,2.847,2.847,0,0,0,2.6-.878,3.625,3.625,0,0,0,.908-2.643.083.083,0,0,0-.1.106" transform="translate(-117.803 -192.435)" />
            <path id="Path_945" data-name="Path 945" d="M162.23,217.488a1.633,1.633,0,0,0-1.542.507,1.448,1.448,0,0,0-.19,1.589,1.3,1.3,0,0,0,1.445.629" transform="translate(-122.724 -193.346)" />
            <path id="Path_946" data-name="Path 946" d="M192.466,323.366a28.256,28.256,0,0,1,1.037-10.272c.725-2.478,1.569-4.675.982-7.222-.574-2.488-.948-4.512.007-7.086" transform="translate(-101.011 -138.207)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
            <path id="Path_947" data-name="Path 947" d="M215.775,284.48c5.806,14.922.832,31.581-3.274,47.057q-4.01,15.114-6.866,30.5" transform="translate(-92.017 -147.906)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
            <path id="Path_948" data-name="Path 948" d="M145.328,268.44a2.572,2.572,0,0,0,.314,1.8,2.1,2.1,0,0,0,.711.728,3.566,3.566,0,0,0,2.363.18" transform="translate(-132.935 -158.781)" />
            <path id="Path_949" data-name="Path 949" d="M167.71,249.643l.2-.163" transform="translate(-117.729 -171.635)" />
          </g>
          <g id="Group_410" data-name="Group 410" transform="translate(312.883 9.507)">
            <path
              id="Path_953"
              data-name="Path 953"
              d="M372.523,275.058c-.015,1.71.136,2.451-1.361,3.1s-3.848.477-5.47.774a27.7,27.7,0,0,0-10.177,3.673c-5.26,3.406-11.548,4.077-17.036,7.573a11.505,11.505,0,0,1-2.874,1.515,12.814,12.814,0,0,1-4.04.178c-3.932-.132-8.118.819-10.88,3.621-.945.96-1.718,2.428-1.07,3.609a3.266,3.266,0,0,0,1.606,1.267c4.777,2.158,9.8.745,14.429-1.027,3.884-1.487,8.69-1.8,12.83-2.1,6.893-.5,13.883-.122,20.686-1.54a43.6,43.6,0,0,0,10.7-3.381c3.067-1.539,6.875-3.087,8.354-6.458a12.87,12.87,0,0,0,.634-2.025q1.918-7.558,3.834-15.117c.973-3.836.859-7.861.752-11.826a34.561,34.561,0,0,0-1.706-9.8c-.769-2.351-1.524-5.351-2.861-7.423a8.447,8.447,0,0,0-6.156-3.77c-4.978-.5-9.707,2.735-9.381,8.006.4,6.44-1.727,12.784-.305,19.187a10.915,10.915,0,0,1,.383,2.314,11.106,11.106,0,0,1-.347,2.267A37.41,37.41,0,0,0,372.523,275.058Z"
              transform="translate(-204.49 -165.876)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <g id="Group_408" data-name="Group 408" transform="translate(94.977)">
              <path
                id="Path_954"
                data-name="Path 954"
                d="M342.985,334.042c2.007,3,3.445,5.836,2.911,9.831-.784,5.873-5.576,9.764-6.841,15.466a3.494,3.494,0,0,0-.06,1.611,3.292,3.292,0,0,0,2.354,2.176c5.544,1.458,10.393-6.688,13.526-9.841l11.825-11.9a3.071,3.071,0,0,0-.315-4.23l-13.2-13.637-1.938-2.029c-3.215-3.363-8.034-3.735-10.678-.824-2.285,2.515-2.1,6.818.211,10.2C341.545,331.971,342.3,333.015,342.985,334.042Z"
                transform="translate(-286.213 -109.702)"
                fill="#110809"
              />
              <g id="Group_406" data-name="Group 406" transform="translate(73.068 84.989)">
                <rect id="Rectangle_82" data-name="Rectangle 82" width="2.938" height="64.799" transform="translate(30.72 149.166) rotate(180)" fill="#110809" />
                <path id="Path_955" data-name="Path 955" d="M404.314,347.29c0-9.568-16.931-13.581-25.391-13.6-10.071-.02-25.391,3.693-25.391,13.6" transform="translate(-349.377 -184.526)" fill="none" stroke="#110809" strokeMiterlimit="10" strokeWidth="2" />
                <line id="Line_13" data-name="Line 13" y1="15.893" transform="translate(29.26 149.429)" fill="none" stroke="#110809" strokeMiterlimit="10" strokeWidth="2" />
                <path id="Path_956" data-name="Path 956" d="M351.056,344.851a4.155,4.155,0,1,0,4.155-4.155A4.153,4.153,0,0,0,351.056,344.851Z" transform="translate(-351.056 -179.778)" fill="#110809" />
                <path id="Path_957" data-name="Path 957" d="M366.063,344.851a4.155,4.155,0,1,0,4.155-4.155A4.153,4.153,0,0,0,366.063,344.851Z" transform="translate(-340.882 -179.778)" fill="#110809" />
                <path id="Path_958" data-name="Path 958" d="M381.069,344.851a4.155,4.155,0,1,0,4.156-4.155A4.155,4.155,0,0,0,381.069,344.851Z" transform="translate(-330.708 -179.778)" fill="#110809" />
                <path
                  id="Path_959"
                  data-name="Path 959"
                  d="M382.8,307.411V259.736a14.941,14.941,0,0,1,14.941-14.94H414.2a14.941,14.941,0,0,1,14.94,14.94v53.3a21.466,21.466,0,0,1-21.4,21.4H354.1a2.517,2.517,0,0,1-2.517-2.519h0A2.516,2.516,0,0,1,354.1,329.4h6.715A21.992,21.992,0,0,0,382.8,307.411Z"
                  transform="translate(-350.7 -244.795)"
                  fill="#fff"
                  stroke="#000"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
              </g>
              <path
                id="Path_960"
                data-name="Path 960"
                d="M347.524,341.447c-.641,3.547-1.582,6.589-4.745,9.089-4.646,3.675-10.8,3.139-15.67,6.356a3.515,3.515,0,0,0-1.165,1.116,3.3,3.3,0,0,0,.18,3.2c2.97,4.9,12.12,2.421,16.563,2.334q8.389-.166,16.775-.331a3.073,3.073,0,0,0,2.715-3.262V340.976l.017-2.807c.029-4.651-3.176-8.269-7.1-8.017-3.391.218-6.252,3.438-6.937,7.475C347.929,338.959,347.744,340.232,347.524,341.447Z"
                transform="translate(-295.241 -101.947)"
                fill="#110809"
              />
              <g id="Group_407" data-name="Group 407" transform="translate(72.346 50.73)">
                <path id="Path_961" data-name="Path 961" d="M357.2,265.762l-.005,0c.02-.065.03-.133.047-.2Z" transform="translate(-346.172 -196.458)" fill="#7a483a" />
                <path id="Path_962" data-name="Path 962" d="M363.981,233.854l-.219,1.04h0Z" transform="translate(-341.72 -217.954)" fill="#7a483a" />
                <path id="Path_963" data-name="Path 963" d="M357.361,265.277c-.035.2-.087.394-.138.589l.16-.755C357.374,265.166,357.371,265.222,357.361,265.277Z" transform="translate(-346.153 -196.763)" fill="#7a483a" />
                <path id="Path_964" data-name="Path 964" d="M350.626,246.833,352.4,232.2l-1.74,14.642Z" transform="translate(-350.626 -219.077)" fill="#7a483a" />
                <path
                  id="Path_965"
                  data-name="Path 965"
                  d="M352.907,237.649c1.3-3.883,3.537-7.509,7.294-9.4a25.484,25.484,0,0,1,3.524-1.354,56.552,56.552,0,0,1,9.336-2.488c.535-.079,1.258-.022,1.379.505a1.426,1.426,0,0,1-.07.624c-.186.841.268,1.569.154,2.391a29.611,29.611,0,0,1-.817,3.2,19.218,19.218,0,0,0-.871,6.665c.347,3.368-1.428,6.2-1.428,9.45-.112.535-1.656,8.125-1.708,8.116l-19.052-3.217C351.232,247.224,351.354,242.27,352.907,237.649Z"
                  transform="translate(-350.611 -224.378)"
                />
              </g>
              <path
                id="Path_966"
                data-name="Path 966"
                d="M323.726,298.066a14.607,14.607,0,0,1,.732-6.193c1.374-4.237,5.482-7.1,9.762-8.328s8.811-1.124,13.261-1.284c8.9-.324,17.843-3.133,26.7-2.76,2.3.1,4.559.732,6.856.643,6.051-.233,11.048-5.353,17.092-5.749a16.547,16.547,0,0,1,5.856.837,17.286,17.286,0,0,1,8.252,4.893c4.151,4.824,3.775,12.187,1.4,18.092a20.206,20.206,0,0,1-4,6.534,21.9,21.9,0,0,1-9.229,5.24c-3.9,1.272-7.123,1.186-11.194,1.153l-27.128-.22c-6.648-.054-13.268.805-19.926.26C333.953,310.513,324.674,306.322,323.726,298.066Z"
                transform="translate(-296.57 -139.756)"
              />
              <path
                id="Path_967"
                data-name="Path 967"
                d="M330.6,282.226a11.868,11.868,0,0,1,11.8-2.064,14.642,14.642,0,0,1,5.918,4.173c8.121,9.214,9.719,24.449,12.113,36q2.094,10.09,3.675,20.278c.3,1.941,3.713,19.265,2.577,19.833-1.111.557-19.778,2.289-19.62,2.7-3.589-9.329-7.816-18.667-10.734-28.2-1.653-5.4-4.329-10.387-6.541-15.561a95.82,95.82,0,0,1-6.032-19.219A17.271,17.271,0,0,1,330.6,282.226Z"
                transform="translate(-296.753 -136.342)"
              />
              <path
                id="Path_968"
                data-name="Path 968"
                d="M358.268,304.628c-.22.294-.431.594-.628.906-5.707,9.078,9.6,11.281,15.125,11.289l19.419.032c11.246,0,19.966-5.636,20.32-16.729l0-33.558q-12.011.03-24.022.059c-4.8.012-10.115-.629-14.867.037-3.4.477-2.975-.294-4.379,3.062-1.764,4.21-.852,10.893-1.621,15.472-.978,5.826-2.861,11.66-6.616,16.221C360.105,302.5,359.112,303.511,358.268,304.628Z"
                transform="translate(-274.374 -145.185)"
                fill="#003bff"
              />
              <path
                id="Path_969"
                data-name="Path 969"
                d="M354.661,326.868c-1.242-.477-2.846-2.626-4.2-2.3-1.26.305-2.379,2.656-2.916,3.688-1.465,2.821-2.284,6.031-4.4,8.479a1.849,1.849,0,0,1-.7.564,1.868,1.868,0,0,1-1.779-.612c-4.062-3.624-4.349-10.9-4.628-15.986a24.393,24.393,0,0,1,11.028-21.458,56.092,56.092,0,0,1,7.521-3.712,163.908,163.908,0,0,0,23.882-13.044,50.876,50.876,0,0,1,10.036-5.054,37.3,37.3,0,0,1,19.481-2.094c7.985,1.292,16,2.92,18.54,14.269,1.968,8.819-1.44,16.951-7.381,19.109-.136.049-.272.077-.406.126L363.6,327.268c-.116.049-.222.106-.344.149a7.778,7.778,0,0,1-.9.267A13.339,13.339,0,0,1,354.661,326.868Z"
                transform="translate(-288.193 -139.417)"
                fill="#003bff"
              />
              <path
                id="Path_970"
                data-name="Path 970"
                d="M358.857,232.191a9.46,9.46,0,0,1,2.238-3.438c5.094-4.744,13.153-4.549,19.647-4.759a64.77,64.77,0,0,1,12.658.611c4.45.733,7.828,2.458,11.778,4.426a24.816,24.816,0,0,1,6.3,3.977c4.148,3.982,5.01,10.239,5.135,15.988s-4.873,64.36-4.566,64.375c-7.376-.342-14.768-.4-22.149-.789-3.775-.2-7.556-.374-11.321-.73-2.056-.2-4.135.037-6.188-.2-2.292-.26-2.057-1.222-3.64-2.215-.79-.5-1.483-.092-2.008-.767-.433-.555-.215-2.708-.262-3.431-.158-2.455-.252-4.915-.5-7.365-.284-2.748-1-5.38-1.213-8.2-.524-6.953-.789-13.962-2.269-20.8-1.326-6.123-3.606-12.014-4.826-18.159a32.939,32.939,0,0,1-.3-12.888A32,32,0,0,1,358.857,232.191Z"
                transform="translate(-274.054 -173.949)"
              />
              <path id="Path_971" data-name="Path 971" d="M382.236,227.761c.921-1.185.69-.963,1.052-2.52L386.9,209.71l-21.842-5.081-2.6,15.768a10.69,10.69,0,0,0,3.379,10.472l1.614,10.937a8.09,8.09,0,1,0,15.905-2.873Z" transform="translate(-270.449 -187.037)" fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
              <path
                id="Path_972"
                data-name="Path 972"
                d="M365.838,223.5c2.594-.094,5.321-1.255,6.054-3.9-.042.148-.539.292-.673.344a9.325,9.325,0,0,1-4.071.324,5.643,5.643,0,0,1-2.561-.663.36.36,0,0,0-.228-.086.282.282,0,0,0-.191.143,1.293,1.293,0,0,0,.169,1.361,2.073,2.073,0,0,1,.628.9,10.078,10.078,0,0,1,.169,1.252C365.151,223.305,365.48,223.416,365.838,223.5Z"
                transform="translate(-269.184 -176.945)"
              />
              <path
                id="Path_973"
                data-name="Path 973"
                d="M364.893,211.768c1.517.5,3.158.871,4.707,1.274,2.247.582,4.526,1.045,6.755,1.7,5.937,1.74,4.6,7.63,4.614,12.6,0,1.369.119,2.96,1.245,3.737,3.126,2.151,3.879-4.539,4.655-6.074.421-.829,1.047-1.552,1.388-2.418.7-1.78.081-3.8.329-5.7.112-.861.406-1.695.476-2.561.3-3.618-3.022-6.953-5.994-8.4a15.655,15.655,0,0,1-6.388-5.059,4.435,4.435,0,0,0-.792-1.049,2.7,2.7,0,0,0-.889-.466,5.794,5.794,0,0,0-5.314.876,6.509,6.509,0,0,1-1.423,1.012,6.028,6.028,0,0,1-2.668.042,4.968,4.968,0,0,0-3.681,1.148,3.3,3.3,0,0,0-.914,3.6c.312.732.926,1.3,1.263,2.015.774,1.651.4,2.948,2.259,3.6Q364.7,211.708,364.893,211.768Z"
                transform="translate(-271.394 -190.819)"
                fill="#110809"
              />
              <path id="Path_974" data-name="Path 974" d="M371.461,215.257a2.314,2.314,0,1,0,2.779-1.73A2.313,2.313,0,0,0,371.461,215.257Z" transform="translate(-264.195 -181.045)" fill="#fff" />
              <path
                id="Path_975"
                data-name="Path 975"
                d="M343.219,296.226A36.448,36.448,0,0,0,339,306.809c-1.467,5.807-2.91,11.806-2.022,17.731.258,1.737.718,3.447.817,5.2.176,3.121-.794,6.208-.762,9.334a35.846,35.846,0,0,0,1,6.623,69.04,69.04,0,0,1,1.25,16.358q-.1,2.688-.232,5.374c-.07,1.435.166,3.47-.515,4.718,2.114.325,4.244.579,6.365.866,3.95.534,7.9,1.069,11.878,1.366,1.232.092,3.9.755,4.948-.057.883-.683,1.005-2.779,1.25-3.76,1.819-7.3,3.389-14.957,1.921-22.466-.265-1.354-.628-2.693-.775-4.066a21.471,21.471,0,0,1,.2-5.153c.955-7.076,2.688-14.006,3.6-21.1.963-7.453,1.606-14.964,2.186-22.454,1.03-13.276-17.186-8.952-23.305-3.415A20.633,20.633,0,0,0,343.219,296.226Z"
                transform="translate(-287.716 -131.49)"
                fill="#003bff"
              />
              <path id="Path_976" data-name="Path 976" d="M308.055,194.162h-.545C307.51,194.224,308.055,194.1,308.055,194.162Z" transform="translate(-307.51 -194.145)" fill="#110809" />
            </g>
            <path
              id="Path_977"
              data-name="Path 977"
              d="M404.132,246.446c-2.363,2.247-6.027,3.967-8.767,5.759a21.667,21.667,0,0,0-8.291-4.633c-1.707-.5-3.475-.8-5.145-1.408-7.588-2.774-13.425-8.485-21.241-11.031-3.918-1.275-8.272-2.2-10.962-5.321-3.3-3.831-3.94-9.564-6.371-13.91-.96-1.713-2.46-3.175-4.447-1.314-1.529,1.433-1,4.237-.68,6.041.487,2.747,1.064,5.475,1.639,8.2.841,3.983,1.289,5.378,4.89,7.329a39.126,39.126,0,0,1,9.939,7.361c1.8,1.93,3.285,4.136,5.022,6.128,6.116,7.009,15.019,10.957,21.8,17.325,3.272,3.074,6.749,7.269,11.7,5.9,1.6-.443,20.476-5.433,19.862-6.517a91.624,91.624,0,0,1-5.638-12.187C406.422,251.566,405.041,249.082,404.132,246.446Z"
              transform="translate(-191.948 -180.873)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path id="Path_978" data-name="Path 978" d="M394.374,240.068a21.118,21.118,0,0,1-11.648,9.016" transform="translate(-161.54 -163.011)" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
            <path id="Path_979" data-name="Path 979" d="M300.356,200.768l-.03.178" transform="translate(-217.404 -189.654)" fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
            <g id="Group_409" data-name="Group 409" transform="translate(65.884 63.431)">
              <path id="Path_980" data-name="Path 980" d="M385.633,285.276H303.4a3.015,3.015,0,0,1-2.948-2.379l-10.212-47.3a3.014,3.014,0,0,1,2.946-3.651h82.232a3.014,3.014,0,0,1,2.948,2.379l10.214,47.3A3.016,3.016,0,0,1,385.633,285.276Z" transform="translate(-290.172 -231.947)" />
              <path id="Path_981" data-name="Path 981" d="M327.638,281l1.628-19.471a3.157,3.157,0,0,0-6.292-.527L321.3,281H309.444v2.017h30.423V281Z" transform="translate(-277.106 -214.213)" />
              <path id="Path_982" data-name="Path 982" d="M313.207,239c-.23-2-3.4-2.025-3.168,0l.748,6.521c.23,2,3.4,2.025,3.168,0Z" transform="translate(-276.711 -228.191)" fill="#fff" />
              <path id="Path_983" data-name="Path 983" d="M316.983,239c-.23-2-3.4-2.025-3.168,0l.75,6.521c.23,2,3.4,2.025,3.168,0Q317.358,242.257,316.983,239Z" transform="translate(-274.151 -228.191)" fill="#fff" />
              <path id="Path_984" data-name="Path 984" d="M320.759,239c-.23-2-3.4-2.025-3.168,0q.375,3.259.75,6.521c.23,2,3.4,2.025,3.168,0Z" transform="translate(-271.591 -228.191)" fill="#fff" />
              <path id="Path_985" data-name="Path 985" d="M324.536,239c-.23-2-3.4-2.025-3.168,0l.748,6.521c.23,2,3.4,2.025,3.17,0Z" transform="translate(-269.03 -228.191)" fill="#fff" />
              <path id="Path_986" data-name="Path 986" d="M328.312,239c-.23-2-3.4-2.025-3.168,0q.375,3.259.75,6.521c.23,2,3.4,2.025,3.168,0Q328.687,242.257,328.312,239Z" transform="translate(-266.47 -228.191)" fill="#fff" />
            </g>
            <path id="Path_987" data-name="Path 987" d="M359.888,263.988c.216,1.445.233,3.136-.857,4.108a4.829,4.829,0,0,1-2.294.918" transform="translate(-179.16 -146.794)" />
            <path id="Path_988" data-name="Path 988" d="M368.157,245.547a2.773,2.773,0,0,0,4.99,1.067" transform="translate(-171.417 -159.296)" fill="#110809" />
            <path id="Path_989" data-name="Path 989" d="M250.915,206.365a1.984,1.984,0,0,0,1.072-.936C252.108,205.429,250.794,206.365,250.915,206.365Z" transform="translate(-250.907 -186.494)" fill="#110809" />
          </g>
          <g id="Group_411" data-name="Group 411" transform="translate(80.93 141.568)">
            <rect id="Rectangle_83" data-name="Rectangle 83" width="511.916" height="5.662" />
            <path id="Path_990" data-name="Path 990" d="M429.023,406.8h-3.9L393.275,273.5h3.9Z" transform="translate(77.565 -272.408)" />
            <path id="Path_991" data-name="Path 991" d="M408.015,406.8h-3.9L372.267,273.5h3.9Z" transform="translate(63.322 -272.408)" />
            <path id="Path_992" data-name="Path 992" d="M116.671,406.8h3.9l31.853-133.3h-3.9Z" transform="translate(-109.961 -272.408)" />
            <path id="Path_993" data-name="Path 993" d="M137.679,406.8h3.9l31.853-133.3h-3.9Z" transform="translate(-95.718 -272.408)" />
          </g>
          <path
            id="Path_994"
            data-name="Path 994"
            d="M324.893,312c-1.707-8.115-16.35-7.479-22.948-8.626-8.959-1.555-17.934-3.056-26.876-4.67-2.017-.349-4.27-.656-6.1.473a8.623,8.623,0,0,0-2.4,2.569c-2.655,3.838-4.931,8.036-5.635,12.479s.379,9.178,3.648,12.006c2.958,2.559,7.2,3.249,11.244,3.772,2.453.319,4.8.883,7.22,1.3,1.4.24,2.8.069,3.973.864,3.5,2.371,2.551,8.006,1.621,11.642-.883,3.455-2.013,7.073-1.131,10.378a25.039,25.039,0,0,1,.763,2.668,10.992,10.992,0,0,1-.238,3.52c-1.1,6.205-3.275,12.857-.894,18.723a9.784,9.784,0,0,1,.765,2.119,7.119,7.119,0,0,1-.049,1.894q-.72,6.31-1.443,12.622c0-.008,14.4.663,15.5.68,1.663.025,5.321.651,6.828-.268,2.332-1.425,2.814-8.395,3.213-10.96.567-3.639.812-7.329,1.51-10.945,1.26-6.521,3.972-12.7,5.158-19.233.592-3.262.369-6.7,1.027-9.915.552-2.708,1.242-5.417,1.7-8.187q1.666-9.972,3.334-19.943a14.728,14.728,0,0,0,.287-4.519C324.948,312.286,324.923,312.14,324.893,312Z"
            transform="translate(68.632 -113.971)"
          />
          <path
            id="Path_995"
            data-name="Path 995"
            d="M320.987,357.5q-.634,1.643-1.267,3.287a28.219,28.219,0,0,0-2.3,8.6c-.148,2.987.658,5.945.72,8.937.107,5.088-2.483,9.475-2.527,14.425-.017,1.841.5,3.646.634,5.482a26.7,26.7,0,0,1-.371,5.564l-1.228,8.957c-.339,2.458-1.1,4.091,1.713,4.363,7.423.717,14.529-.418,21.916-.9a.976.976,0,0,0,1.143-.886c2.671-10.358,5.2-20.352,6.487-30.96a9.218,9.218,0,0,1,.631-2.782,14.871,14.871,0,0,1,2.2-2.9c1.893-2.351,1.938-4.8,2.755-7.522.792-2.644,1.732-5.183,2.322-7.93q2.1-9.761,4.16-19.528c1.139-5.4,2.492-10.224-.371-15.37a15.042,15.042,0,0,0-3.88-4.165,77.565,77.565,0,0,0-45.924-17.113c-6.218-.2-14.026-.6-16.353-6.373-.878-2.173-.664-4.615-1.017-6.932-1.032-6.764-11.531-8.346-17-6.583-7.375,2.381-9.678,11.529-10.5,19.233-.669,6.306-1.111,12.806.7,18.882,2.7,9.054,10.259,16.118,18.914,19.912,6.806,2.985,14.232,4.079,21.6,4.514,5.633.332,11.192-.732,16.763-1.361,3.953-.446,3.344.668,2.079,3.952Q321.987,354.908,320.987,357.5Z"
            transform="translate(69.731 -122.018)"
            fill="#003bff"
          />
          <path
            id="Path_996"
            data-name="Path 996"
            d="M275.728,234.125q.31,1.472.567,2.953c.336,1.945.524,4.183-.79,5.655-.762.852-1.908,1.284-2.678,2.128-1.134,1.243-1.228,3.118-.978,4.78a56.357,56.357,0,0,1,.43,5.7c.111,2.092,1.025,4.024.936,6.172a6.4,6.4,0,0,1-7.034,6.46c-2.317-.161-4.931-.475-6.328-2.332a8.182,8.182,0,0,1-1.2-3.16c-1.9-8.323-3.809-17.036-1.658-25.3.794-3.056,1.8-6.667,3.616-9.291,1.316-1.9,2.772-4.376,4.6-5.812,1.695-1.329,4.264-1.807,5.985-.513a7.155,7.155,0,0,1,2.143,3.665A83.126,83.126,0,0,1,275.728,234.125Z"
            transform="translate(65.454 -166.565)"
            fill="#fff"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_997"
            data-name="Path 997"
            d="M280.425,273.665a10.8,10.8,0,0,1,4.945-7.554,57.6,57.6,0,0,1,9.137-4.581c.423-.156.844-.309,1.269-.46,8.375-2.988,17.182-4.827,25.421-8.19,9.116-3.723,17.889-8.153,26.824-12.273a91.573,91.573,0,0,0,17.109-9.739c4.49-3.386,3.079-10.162,5.23-14.815.926-2,2.675-4.084,4.289-1.368,1.5,2.525,1.065,5.869,1.43,8.667.364,2.782.733,5.606-.5,8.257a15.271,15.271,0,0,1-3.967,4.66l-4.428,3.97c-1.7,1.522-3.371,3.158-5.173,4.522q-8.457,6.4-16.293,13.561c-5.15,4.708-9.845,9.543-15.764,13.38a67.569,67.569,0,0,1-18.812,8.606c-.109.028-.216.057-.324.087-6.539,1.78-12.518,4.443-19.53,3.72C285.831,283.554,279.378,279.973,280.425,273.665Z"
            transform="translate(81.911 -171.591)"
            fill="#fff"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_998"
            data-name="Path 998"
            d="M302.666,322.569c0,.2-17.939,1.114-19.59,1.218-3.235.2-7.4,1.193-10.583.653-2.034-.346-2.9-2.493-5.309-2.042-1.445.274-3.155.794-4.217-.221a3.816,3.816,0,0,1-.831-2.638q-.559-10.128-1.118-20.255c-.307-5.576-7.175-7.1-11.185-9.645l-8.2-5.19a108.229,108.229,0,0,1,20.644-29.389c8.8-9.17,15.053-10,27.928-10.128a127.761,127.761,0,0,0,30.868-4.1c2.408,8.541,4.958,17.159,7.274,25.659-5.911,3.208-13.093,5.52-19.511,7.66-1.911.725-3.967,1.566-5.007,3.326a9.155,9.155,0,0,0-.861,4.653Q302.82,302.348,302.666,322.569Z"
            transform="translate(55.692 -152.985)"
          />
          <g id="Group_414" data-name="Group 414" transform="translate(523.005 201.557)">
            <line id="Line_14" data-name="Line 14" x1="30.678" transform="translate(28.544 163.63)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
            <g id="Group_413" data-name="Group 413">
              <g id="Group_412" data-name="Group 412">
                <path
                  id="Path_999"
                  data-name="Path 999"
                  d="M398.916,395.109a8.343,8.343,0,0,1,.1-.908c.544-3.3,3.136-5.8,4.911-8.635a18.909,18.909,0,0,0,2.856-9.2c.255-6.083.079-13.89,2.7-19.521,2.361-5.077,7.977-7.833,10.858-12.6,1.589-2.634,2.082-5.76,2.792-8.756,1.128-4.765,2.935-9.486,6.13-13.2,2.488-2.889,5.7-5.042,8.873-7.16a44.725,44.725,0,0,1,9.707-5.369c4.591-1.621,9.687-2.158,11.816,2.574,1.718,3.819.852,9.236-.96,12.833-1.577,3.129-4.074,5.677-6.423,8.277a120.771,120.771,0,0,0-11.152,14.405,11.157,11.157,0,0,0-2.071,4.6,19.936,19.936,0,0,0,.5,4.891c1.044,8.282-2.373,14.976-8.767,20.02-5.051,3.983-8.2,6.237-9.474,13.132-.785,4.265-1.138,8.794-3.509,12.425a42.307,42.307,0,0,1-3.384,4.1,32.211,32.211,0,0,0-7.5,17.89c-.29,3.274-.982,10.657-6.244,9.172-6.437-1.817-5.653-20.62-3.767-25.075.8-1.893,2.009-3.646,2.389-5.667C399.809,400.626,398.767,397.839,398.916,395.109Z"
                  transform="translate(-362.956 -308.599)"
                />
                <path
                  id="Path_1000"
                  data-name="Path 1000"
                  d="M385.867,399.92a9.827,9.827,0,0,1-3.316-3.636c-1.963-4.124,1.071-6.948,1.3-10.829.206-3.512-2.826-5.729-4.75-8.172a11.045,11.045,0,0,1-1.386-11.071c.7-1.571,1.8-3.128,1.562-4.831a9.519,9.519,0,0,0-1.691-3.344,10.593,10.593,0,0,1-.238-10.279c.961-1.782,2.463-3.329,2.874-5.312a10.536,10.536,0,0,0-.22-4.039,53.587,53.587,0,0,1-1.284-13.1,10.577,10.577,0,0,1,1.582-5.474c.992-1.413,2.7-2.562,4.49-2.089,1.871.493,2.119,1.987,2.78,3.493,1.911,4.358,2.421,8.274,3.259,12.95a5.738,5.738,0,0,0,.817,2.334,7.219,7.219,0,0,0,2.111,1.727c4.95,3.21,8.99,8.506,8.962,14.405-.017,3.458-1.4,6.8-1.451,10.252a4.06,4.06,0,0,0,.618,2.53,5.382,5.382,0,0,0,1.992,1.363,11.005,11.005,0,0,1,5.212,4.822c1.161,2.47.868,5.393.047,8s-2.129,5.027-3,7.615a4.867,4.867,0,0,0-.341,1.988c.158,1.611,1.482,2.379,2.349,3.569a10.637,10.637,0,0,1,1.5,3.537c1.292,3.8,2.636,6.91,2.017,11.056-.245,1.646-.732,3.254-.909,4.91-.353,3.306.537,6.6,1.047,9.883.341,2.188,1.159,7.286-1.084,8.8-1.93,1.3-4.67-.839-6.289-1.78-5.091-2.96-8.675-5.338-9.487-11.427-.579-4.337,0-9.324-2.866-12.994C390.47,402.715,387.985,401.457,385.867,399.92Z"
                  transform="translate(-376.132 -302.473)"
                  fill="#003bff"
                />
                <path
                  id="Path_1001"
                  data-name="Path 1001"
                  d="M402.94,381.429a37.634,37.634,0,0,1,5-9.118c1.513-2.025,3.232-3.9,4.579-6.044,2.156-3.43,3.287-7.418,5.378-10.89,3.207-5.319,9.608-8.358,15.477-5.324a26.278,26.278,0,0,1,5.529,4.2,46.006,46.006,0,0,1,5.4,5.589c3.192,4.114,5.015,9.1,6.791,13.991q3.146,8.671,6.292,17.34c1,2.748,3.332,9.014,1.04,11.732-2.225,2.638-7.12-1.161-8.131-3.348a9.931,9.931,0,0,0-2.928-3.673c-1.586-1.227-3.616-2.047-4.594-3.8-1.017-1.822-.839-4.524-2.7-5.472a13.046,13.046,0,0,0-1.725-.53c-1.6-.55-2.616-2.087-3.482-3.541-1.975-3.306-3.483-7.027-5.6-10.212-1.342.4-2.534,4.46-3.452,5.75a20.17,20.17,0,0,1-4.871,4.843c-2.879,2.005-6.4,3.369-8.3,6.317A20.918,20.918,0,0,0,410.6,394.7c-.807,2.673-3.336,10.38-7.465,9.074-2.834-.9-2.695-5.67-2.735-7.93A38.676,38.676,0,0,1,402.94,381.429Z"
                  transform="translate(-359.685 -281.312)"
                  fill="#003bff"
                />
                <path id="Path_1002" data-name="Path 1002" d="M439.045,377.958H379.179v7.484h4.806l3.9,22.1a21.555,21.555,0,0,0,21.228,17.808h0a21.558,21.558,0,0,0,21.228-17.808l3.9-22.1h4.806Z" transform="translate(-374.067 -261.577)" />
                <line id="Line_15" data-name="Line 15" x2="53.63" transform="translate(8.23 124.488)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
              </g>
            </g>
          </g>
          <g id="Group_415" data-name="Group 415" transform="translate(287.319 128.175)">
            <path id="Path_1003" data-name="Path 1003" d="M267.042,299.416l14.479,1.745c8.668,1.044,18.087,2.45,24.077,8.8,3.742,3.968,5.6,9.415,6.172,14.84s-.037,10.9-.646,16.315" transform="translate(-214.405 -241.443)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
            <rect id="Rectangle_84" data-name="Rectangle 84" width="2.938" height="64.799" transform="translate(39.749 97.485)" fill="#110809" />
            <path id="Path_1004" data-name="Path 1004" d="M244.929,375.178c0-9.568,16.931-13.58,25.391-13.6,10.071-.02,25.391,3.693,25.391,13.6" transform="translate(-229.396 -199.298)" fill="none" stroke="#110809" strokeMiterlimit="10" strokeWidth="2" />
            <line id="Line_16" data-name="Line 16" y1="15.893" transform="translate(41.209 162.545)" fill="none" stroke="#110809" strokeMiterlimit="10" strokeWidth="2" />
            <path id="Path_1005" data-name="Path 1005" d="M281.026,372.739a4.155,4.155,0,1,1-4.155-4.155A4.153,4.153,0,0,1,281.026,372.739Z" transform="translate(-210.557 -194.55)" fill="#110809" />
            <path id="Path_1006" data-name="Path 1006" d="M266.019,372.739a4.155,4.155,0,1,1-4.155-4.155A4.153,4.153,0,0,1,266.019,372.739Z" transform="translate(-220.731 -194.55)" fill="#110809" />
            <path id="Path_1007" data-name="Path 1007" d="M251.012,372.739a4.155,4.155,0,1,1-4.155-4.155A4.154,4.154,0,0,1,251.012,372.739Z" transform="translate(-230.905 -194.55)" fill="#110809" />
            <path
              id="Path_1008"
              data-name="Path 1008"
              d="M296.818,337.948V282.3c0-9.63-8.828-17.437-19.718-17.437H255.39c-10.89,0-19.718,7.808-19.718,17.437V344.51c0,13.739,12.711,24.981,28.247,24.981H334.7a3.149,3.149,0,0,0,3.324-2.94h0a3.149,3.149,0,0,0-3.324-2.938h-8.861C309.813,363.614,296.818,352.123,296.818,337.948Z"
              transform="translate(-235.672 -264.866)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
          </g>
          <path
            id="Path_1009"
            data-name="Path 1009"
            d="M264.054,223.624a30.477,30.477,0,0,0-8.638,1.274c-5.388,1.918-7.846,5.945-10.585,10.435-2.754,4.515-7.381,7.257-10.843,11.148a22.793,22.793,0,0,0-1.963,26.867,27.944,27.944,0,0,0,6.591,6.838c14.113,10.675,37.132,3.049,45.338-11.939,4.336-7.918,2.685-17.632.916-26.485-.971-4.869-.409-8.7,4.646-11.227a35.784,35.784,0,0,0,3.941-1.852,7.987,7.987,0,0,0,2.656-9.438,10.09,10.09,0,0,0-8.227-5.888,9.116,9.116,0,0,0-4.515.577c-3.349,1.409-5.232,4.987-8.217,7.056C271.955,223.208,268.027,223.418,264.054,223.624Z"
            transform="translate(46.818 -171.656)"
            fill="#fff"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path id="Path_1010" data-name="Path 1010" d="M305.162,246.73c-.165.076-.111.326-.015.482a3.188,3.188,0,0,0,2.973,1.351,5.5,5.5,0,0,0,3.061-1.435" transform="translate(98.686 -148.987)" />
          <g id="Group_416" data-name="Group 416">
            <path id="Path_1011" data-name="Path 1011" d="M105.241,373.314a36.3,36.3,0,0,1,12.88,1.163q-.048-10.936-.6-22c-.243-4.858-2.059-9.318-7.653-10.267-3.393-.577-6.009.564-7.054,3.381C100.282,352.413,103.462,365.536,105.241,373.314Z" transform="translate(-39.065 -84.352)" fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
            <path
              id="Path_1012"
              data-name="Path 1012"
              d="M107.066,371.4c.5,4.658-.3,9.95-.48,14.694-.2,5.436,2.344,9.588,2.963,14.783a110.047,110.047,0,0,1,.507,15.031.384.384,0,0,0,.386.393h31.651a.39.39,0,0,0,.386-.352l.606-6.1c.461-4.648.577-8.45-.723-12.877A58.626,58.626,0,0,1,141,391.217c-.577-3.089-.963-6.242-1.258-9.276-.453-4.665-.446-9.363-.849-14.033-.391-4.552-1.17-9.059-1.946-13.561-2.356-13.638-4.723-27.289-7.994-40.744-1.644-6.766-3.515-13.479-5.727-20.082a118.617,118.617,0,0,0-10.09-22.528c-2.529-4.306-5.541-9.011-10.585-10.595-3.975-1.248-8.464-1.812-12.408-.154-5.333,2.24-9.46,6.611-13.855,10.222l-1.8,8.8c-1.423,6.958.453,14.977,3.812,21.12a75.553,75.553,0,0,1,3.78,6.853c1.507,3.592,1.8,7.571,2.957,11.291a57.213,57.213,0,0,0,4.942,10.388C96.983,341.648,105.5,356.745,107.066,371.4Z"
              transform="translate(-57.954 -140.523)"
            />
            <path
              id="Path_1013"
              data-name="Path 1013"
              d="M137.314,293.677c.7,3.059.195,6.918,2.161,9.595,1.935,2.634,7.868,3.539,10.88,4.623a4.13,4.13,0,0,1,1.8,1.022c1.121,1.292.121,3.483-1.45,4.161s-3.374.32-5.044-.049q-5.915-1.306-11.828-2.616a8.353,8.353,0,0,1-2.354-.772c-2.01-1.138-2.717-3.66-3.025-5.948-2.2-16.337-17.526-27.173-22.718-42.472a95.559,95.559,0,0,1-2.957-12.065c-1.183-5.928-2.28-11.882-3.549-17.795-.913-4.249-3.311-8.031-.763-12.259a8.479,8.479,0,0,1,9.927-3.343c5.693,2.109,7.662,8.452,9.418,13.563,2.448,7.118,4.48,13.586,4.059,21.258-.112,2.029-.352,4.129.344,6.037.779,2.134,2.6,3.683,4.054,5.425,4.921,5.871,5.695,13.763,7.464,20.926.893,3.609,2.648,6.962,3.529,10.5C137.282,293.538,137.3,293.606,137.314,293.677Z"
              transform="translate(-42.141 -170.315)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path id="Path_1014" data-name="Path 1014" d="M69.705,376.928a35.217,35.217,0,0,1,12.576,1.116q2.822-10.493,5.17-21.1c1.03-4.66.386-8.939-4.958-9.851a7.549,7.549,0,0,0-7.942,3.243C70.234,356.874,69.968,369.466,69.705,376.928Z" transform="translate(-60.871 -81.043)" fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
            <path
              id="Path_1015"
              data-name="Path 1015"
              d="M104.248,376.166c-2.992,1.925-6.66,2.111-10.1,2.032-.347-.008-.695-.017-1.042-.017q-8.087-.005-16.155-.581c-2.2-.158-4.7-.374-6.551-1.708-2.17-1.567-1.851-4.228-1.322-6.579a35.791,35.791,0,0,0,.829-6.133c2.507.77,5.348,1.586,7.97,1.32a13.614,13.614,0,0,0,4.265-1.124,11.4,11.4,0,0,0,1.906-1.092c.321-.23,1.223-1.3,1.6-1.215.351.079.591,1.024.76,1.3a9.012,9.012,0,0,0,1.493,1.74,10.8,10.8,0,0,0,3.744,2.306,24.7,24.7,0,0,0,3.935.906q2.834.514,5.63,1.217a10.565,10.565,0,0,1,3.312,1.27,3.89,3.89,0,0,1,1.852,2.894,3.945,3.945,0,0,1-1.856,3.282C104.433,376.043,104.34,376.105,104.248,376.166Z"
              transform="translate(-61.558 -71.473)"
            />
            <path
              id="Path_1016"
              data-name="Path 1016"
              d="M137.464,369.987c-.326,1.517-2.138,2.65-3.77,3.134-7.23,2.141-15,.089-22.515.7a17.118,17.118,0,0,1-5.551.01c-4.126-1.037-4.014-6.153-3.039-9.474.45-1.529,1.208-3.1.782-4.638a28.268,28.268,0,0,0,10.135.248,8.264,8.264,0,0,0,3.589-1.25c.446-.32.953-.742,1.473-.567a1.6,1.6,0,0,1,.74.772c1.582,2.576,3.8,3.535,6.354,4.841a15.779,15.779,0,0,0,3.153,1.463c1.49.408,3.014.693,4.524,1.019,1.713.369,3.75,1.044,4.121,2.757A2.321,2.321,0,0,1,137.464,369.987Z"
              transform="translate(-38.959 -73.476)"
              fill="#003bff"
            />
            <path
              id="Path_1017"
              data-name="Path 1017"
              d="M111.038,328.855c-.782-7.71-1.049-15.451-1.435-23.255a205.629,205.629,0,0,0-2.487-22.1c-.428-2.715-.091-5.121-.961-7.794a47.7,47.7,0,0,0-4.374-9.918c-2.742-4.525-6.7-8.479-11.707-10.256a12.086,12.086,0,0,0-10.3.646c-3.042,1.829-4.713,4.836-6.24,7.935q-1.374,2.784-2.745,5.567a73.081,73.081,0,0,0-5.232,12.509,24.513,24.513,0,0,0-.324,13.4c.817,2.777,2.393,4.3,3.71,6.727a22.422,22.422,0,0,1,2.737,9.784c.092,2.458-.258,4.91-.351,7.37a54.4,54.4,0,0,0,2.337,16.337,29.859,29.859,0,0,1,1.319,6.413,49.907,49.907,0,0,1-.314,5.385c-.3,4.864.629,9.682.886,14.524a29.519,29.519,0,0,1-2.594,13.508,24.534,24.534,0,0,0-1.832,9.172c-.022,4.8.774,9.683-.654,14.36-.433,1.416-1.067,2.758-1.626,4.129-2.4,5.88-3.378,12.222-4.291,18.511a.6.6,0,0,0,.515.688c2.208.284,9.687,1.775,10.373,1.856,6.853.8,13.152-1.525,19.815.032a.605.605,0,0,0,.711-.378,276.87,276.87,0,0,0,11.836-43.556c.8-4.287.225-8.338.409-12.556a28.25,28.25,0,0,1,.973-5.46c2.433-8.392,4.363-16.342,2.943-25.122C111.681,334.5,111.325,331.678,111.038,328.855Z"
              transform="translate(-64.441 -143.59)"
              fill="#003bff"
            />
            <path
              id="Path_1018"
              data-name="Path 1018"
              d="M117.376,215.983a23.861,23.861,0,0,0-11.185-5.462,9.266,9.266,0,0,0-4.462.037,11.558,11.558,0,0,0-2.69,1.389A37.881,37.881,0,0,0,84.5,227.829c-2.54,5.5-3.842,11.481-4.953,17.436-.649,3.488-6.969,46.023-9.046,45.417,2.472.722,5,1.25,7.417,2.165,3.47,1.312,6.943,2.671,10.356,4.124,6.848,2.916,13.479,6,20.7,7.774a7.5,7.5,0,0,0,2.327-2.91,45.807,45.807,0,0,0,3.589-11.862c2.274-14.727,3.364-30.483,7.155-44.889,1.727-6.564,2.93-13.887.968-20.536A18.508,18.508,0,0,0,117.376,215.983Z"
              transform="translate(-60.334 -173.708)"
            />
            <path
              id="Path_1019"
              data-name="Path 1019"
              d="M106.774,287.683c1.824.829,3.7,1.623,5.589,2.493A125.2,125.2,0,0,1,136.8,305.062c-2.212,2.081-4.2,4.7-4.381,7.734s2.156,6.326,5.188,6.185c1.75-1.967,1.988-4.839,3.259-7.146,1.654-3,6.593-2.9,7.705-5.155,1.175-2.379-1.493-5.583-2.814-7.279-3.769-4.842-9.637-7.646-13.425-12.412-1.354-1.7-2.332-3.666-3.542-5.472a25.266,25.266,0,0,0-7.477-7.274c-5.8-3.661-7.861-8.813-10.7-14.867a31.87,31.87,0,0,1-2.252-5.75c-.639-2.574-.624-5.26-1.03-7.881a39.141,39.141,0,0,0-3.638-10.729A27.308,27.308,0,0,0,98,226.492c-2.522-2.337-6.01-3.851-9.4-3.279-4.207.708-7.378,4.489-8.6,8.578s-.846,8.465-.351,12.7c.477,4.079,1.086,8.241,3.007,11.87,1.842,3.482,4.53,6.455,6.482,9.883C91,269.51,92,273.04,93.619,276.429,96.5,282.45,101.376,285.225,106.774,287.683Z"
              transform="translate(-54.444 -165.013)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path
              id="Path_1020"
              data-name="Path 1020"
              d="M95.186,213.58a11.154,11.154,0,0,0-1.931,3.9c-2.005,7.349.839,16.4,3.911,23.053,1.129,2.446,1.591,6.581,4.316,7.865,1.857.876,3.207-.5,4.749-1.279,1.829-.925,3.782-1.693,5.628-2.649q6.2-3.214,12.164-6.876c-2.149-10.036-6.962-20.25-15.92-25.26-2.868-1.6-6.366-2.6-9.433-1.421A8.2,8.2,0,0,0,95.186,213.58Z"
              transform="translate(-45.36 -173.617)"
            />
            <path
              id="Path_1021"
              data-name="Path 1021"
              d="M89.174,218.127a39.73,39.73,0,0,0,1.727-8.254c.3-3.259.3-6.62,1.493-9.668,1.844-4.715,8.433-7.774,13.263-6.611,6.792,1.633,4.749,13.043,4.121,18.127-.307,2.48-2.181,4.242-3.5,6.3-2.2,3.421-2.5,9.068-2.836,13.078a2.416,2.416,0,0,1-.364,1.314,2.363,2.363,0,0,1-1.421.68,36.155,36.155,0,0,1-8.314,1.161c-3.7-.064-9.5-1.347-7.777-6.123C86.81,224.671,88.137,221.458,89.174,218.127Z"
              transform="translate(-50.332 -185.178)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path
              id="Path_1022"
              data-name="Path 1022"
              d="M106.7,188.494a7.753,7.753,0,0,0-5.663,1.906A7.445,7.445,0,0,1,99,192.042a6.046,6.046,0,0,1-1.285.289,14.332,14.332,0,0,0-9.739,6.539,6.36,6.36,0,0,0-.987,2.482,7.722,7.722,0,0,0,.114,2.133q.378,2.462.936,4.893a11.968,11.968,0,0,1,.466,2.2,6.606,6.606,0,0,0,.121,1.812c.742,2.5,3.155,1.349,4.161-.285a26.125,26.125,0,0,0,1.881-4.26,10.819,10.819,0,0,1,3.193-4.893,11.729,11.729,0,0,1,5.319-1.811c2.012-.324,4.811-.591,6.282-2.139a8.53,8.53,0,0,0,2.651-6.349,3.911,3.911,0,0,0-.475-1.676,4.17,4.17,0,0,0-1.409-1.364A7.417,7.417,0,0,0,106.7,188.494Z"
              transform="translate(-49.18 -188.479)"
            />
            <path id="Path_1023" data-name="Path 1023" d="M95.388,200.231a2.877,2.877,0,0,0-3.943-1.253A4.958,4.958,0,0,0,89.206,204c.314,1.591,1.94,2.737,3.395,1.829a3.851,3.851,0,0,0,1.76-1.044A4.1,4.1,0,0,0,95.388,200.231Z" transform="translate(-47.701 -181.593)" fill="#fff" />
            <path id="Path_1024" data-name="Path 1024" d="M91.752,200.881c-.176-.815-1.332-.446-1.223.337a2.856,2.856,0,0,0,.527,1.717.509.509,0,0,0,.841-.222A3.135,3.135,0,0,0,91.752,200.881Z" transform="translate(-46.759 -180.382)" />
            <path id="Path_1025" data-name="Path 1025" d="M91.074,254.6a2.4,2.4,0,0,0-.079,1.571,3,3,0,0,0,1.748,1.817,1.894,1.894,0,0,0,.579.159,2.482,2.482,0,0,0,1.364-.456" transform="translate(-46.503 -143.651)" />
            <path id="Path_1026" data-name="Path 1026" d="M77.38,234.449a18.431,18.431,0,0,0-.267,4.3c.275,5.823,1.618,11.528,2.945,17.182,3.195-3.061,7.588-4.458,11.814-5.76l13.367-4.119c1.269-.391,2.725-.967,3.069-2.248a3.76,3.76,0,0,0,.034-1.364c-.888-8.356-7.036-23.688-17.83-21.782-3.272.577-6.017,2.78-8.331,5.165A16.517,16.517,0,0,0,77.38,234.449Z" transform="translate(-55.866 -166.773)" />
            <path id="Path_1027" data-name="Path 1027" d="M90.239,222.084c-.267-.2-.549.218-.342.445a35.548,35.548,0,0,1,6.255,8.87c1.753,3.713,2.613,7.693,4.106,11.494a.263.263,0,0,0,.507-.139C99.515,235.479,96.369,226.653,90.239,222.084Z" transform="translate(-47.231 -165.731)" fill="#fff" />
            <path id="Path_1028" data-name="Path 1028" d="M94.626,206.453c.166.265.418.819.48.951.274.582.508,1.178.735,1.78a21.052,21.052,0,0,1,1.819,7.556c.023-1.837.983-3.3,1.579-4.965a24.112,24.112,0,0,1,2.587-4.723c.433-.678.753-1.965-.354-1.114a4.526,4.526,0,0,1-2.181.864,14.61,14.61,0,0,1-4.786-.488C94.532,206.319,94.574,206.372,94.626,206.453Z" transform="translate(-44.058 -176.833)" />
            <path id="Path_1029" data-name="Path 1029" d="M96.222,201.95a1,1,0,0,0,.183.774,3.135,3.135,0,0,0,1.144,1.154,2.017,2.017,0,0,0,1.587.176,2.553,2.553,0,0,0,1.406-1.529c.055-.133.084-.327-.047-.388" transform="translate(-42.911 -179.346)" />
          </g>
          <g id="Group_419" data-name="Group 419" transform="translate(71.54 226.515)">
            <g id="Group_418" data-name="Group 418">
              <path
                id="Path_1030"
                data-name="Path 1030"
                d="M140.9,401.238c.178-2.586.384-4.755.4-6.01a40.324,40.324,0,0,0-.339-7.046,51.927,51.927,0,0,0-3-9.353c-2.532-6.818-4.24-14.244-2.678-21.349,1.05-4.779,3.53-9.158,4.527-13.951.723-3.467.649-7.057,1.292-10.541.527-2.844,4.143-12.226,8.554-8.756a5.278,5.278,0,0,1,1.48,2.391c1.238,3.294,1.975,6.8,3.66,9.888,1.8,3.3,4.624,6.019,6.027,9.509,1.354,3.366,1.25,7.133.849,10.739-1.4,12.553-5.611,24.572-3.861,37.44.3,2.178.921,20.018-2.275,20.018-1.087,2.9-2.846,7.4-6.276,8.146C139.963,424.4,140.272,410.275,140.9,401.238Z"
                transform="translate(-88.361 -323.473)"
                fill="#003bff"
              />
              <path
                id="Path_1031"
                data-name="Path 1031"
                d="M127.472,397.881c-3.645,3.433-6.354,7.739-11.786,8.618-6.8,1.1-10.266-5.338-7.833-11.323,1.6-3.938,4.937-6.85,7.829-9.965a55.847,55.847,0,0,0,8.256-11.556c1.673-3.116,3.163-6.529,6.015-8.616a19.649,19.649,0,0,1,6.378-2.66c4.4-1.252,9.128-2.5,13.466-1.045a19.055,19.055,0,0,1,7.019,4.806c11.851,11.853,10.967,27.193,8.229,42.469-.248,1.388-.624,2.962-1.869,3.628-2.005,1.074-4.265-1.139-4.973-3.3-1.71-5.23-.678-9.86-4.108-14.5-2.779-3.759-7.816-6.737-9.34-11.2-.466-1.361-.668-2.869-1.574-3.983s-2.921-1.492-3.675-.267a3.665,3.665,0,0,0-.238,2.211,14.453,14.453,0,0,1-5.822,12.521c-1.6,1.158-3.435,1.977-4.945,3.247C128.148,397.259,127.805,397.567,127.472,397.881Z"
                transform="translate(-107.076 -298.25)"
              />
              <path
                id="Path_1032"
                data-name="Path 1032"
                d="M143.654,381.345a14.427,14.427,0,0,1,.064-2.067c.807-7.225,10.855-8.25,15.84-11.481,4.754-3.082,7.683-9.538,13.818-3.207,2.94,3.037,4.925,8.546,5.522,12.659q1.732,11.91,3.326,23.842c.237,1.772.451,3.673-.364,5.264-1.512,2.953-5.989,3.2-8.662,1.233-3.733-2.3-5.1-8.533-6.542-12.283a13.121,13.121,0,0,1-1.243-5.505c.163-2.329,1.564-4.547,1.193-6.851-.117-.725-.619-1.552-1.347-1.456a1.577,1.577,0,0,0-.631.279c-3.3,2.131-4.4,5.467-6.43,8.579-2.134,3.272-3.807,2.891-6.272,5.148-5.119,4.683-3.567,15.244-5.42,21.538-.475,1.613-2.047,3.527-3.44,2.586a2.785,2.785,0,0,1-.93-1.74,45.067,45.067,0,0,1,.057-21.421c.737-2.96,1.78-5.886,1.864-8.935C144.112,385.464,143.724,383.4,143.654,381.345Z"
                transform="translate(-84.164 -297.417)"
                fill="#003bff"
              />
              <g id="Group_417" data-name="Group 417" transform="translate(32.819 106.822)">
                <path id="Path_1033" data-name="Path 1033" d="M175.719,387.135H126.635v6.135h3.94l3.2,18.122a17.675,17.675,0,0,0,17.405,14.6h0a17.672,17.672,0,0,0,17.4-14.6l3.2-18.122h3.94Z" transform="translate(-126.635 -387.135)" />
                <line id="Line_17" data-name="Line 17" x2="43.971" transform="translate(2.556 6.647)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
              </g>
            </g>
            <line id="Line_18" data-name="Line 18" x2="46.236" transform="translate(47.061 145.966)" fill="#003bff" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
          </g>
          <path id="Path_1034" data-name="Path 1034" d="M372.9,214.26a.973.973,0,0,1,.925,1.535,1.561,1.561,0,0,1-.77.507c-.029.01-.076.007-.07-.023" transform="translate(144.679 -171.007)" />
          <path id="Path_1035" data-name="Path 1035" d="M363.42,215.71a1.082,1.082,0,0,0,.237.861,2.8,2.8,0,0,0,1.154,1.007,2.334,2.334,0,0,0,1.816-.008,2.61,2.61,0,0,0,1.307-1.274h-.106" transform="translate(138.238 -170.017)" />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgTeam;
