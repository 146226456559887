import React from 'react';

const SvgArrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 50 80" {...props}>
    <polyline
      strokeWidth="1"
      //   strokeLinecap="round"
      //   strokeLinejoin="round"
      points="
	0.375,0.375 45.63,38.087 0.375,75.8 "
    />
  </svg>
);
export default SvgArrow;
