import React from 'react';
import { Accordion, Card, Modal, Form, Col, Container, Row, Alert } from 'react-bootstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SvgTick from '../SVGs/Tick';
import SvgClose from '../SVGs/SvgClose';
import SvgTeam from '../SVGs/SvgTeam';
import SvgArrow from '../SVGs/SvgArrow';
import DropZone from '../components/DropZone';
import careerDetailsArray from '../components/carrer.constants';

const AlertNotification = ({ show, setShow, snackbarMessage }) => {
  React.useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, []);

  return (
    <Alert className="alertBox m-0" show={show} variant={snackbarMessage !== 'Something went Wrong!!!!..' ? 'success' : 'danger'} onClose={() => setShow(false)}>
      <div className="d-flex col p-0 align-items-center">
        <SvgTick style={{ fill: '#fff', width: 25, height: 25 }} />
        <p className="m-0 pl-2" style={{ flex: 1 }}>
          {snackbarMessage}
        </p>
      </div>
      <SvgClose style={{ height: 20, width: 20, stroke: 'white', strokeWidth: 4 }} onClick={() => setShow(false)} />
    </Alert>
  );
};

const DialogBox = ({ isDialogOpen, setApplicantData, setSnackbarMessage, applicantData, handleOnchange, SetIsDialogOpen, setShow }) => {
  const [validated, setValidated] = React.useState(false);
  const [isloading, setIsloading] = React.useState(false);
  const [fileValidation, setFileValidation] = React.useState(false);
  const [dropZone, setDropZone] = React.useState();

  const handleDialogConfirmAction = async () => {
    try {
      setIsloading(true);
      if (dropZone && dropZone.event) dropZone.event.processQueue();

      const formdata = new FormData();

      if (applicantData && applicantData.resume) formdata.append('file', applicantData.resume, applicantData.resume.name);

      formdata.append('email', applicantData.email);
      formdata.append('jobTitle', applicantData.jobTitle);
      formdata.append('name', applicantData.fullName);
      formdata.append('message', applicantData.message);
      if (applicantData && !applicantData.resume) {
        setFileValidation(true);
        if (document.getElementsByClassName('filepicker') && document.getElementsByClassName('filepicker').length) document.getElementsByClassName('filepicker')[0].classList.add('error-upload');
        setIsloading(false);
        return;
      }
      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };

      const response = await fetch('https://us-central1-thpillar-219209.cloudfunctions.net/apiEndPoints/api/v1/job/apply', requestOptions);
      const json = await response.json();
      setApplicantData(null);
      if (json.success) {
        setSnackbarMessage(' Thanks for applying');
        setIsloading(false);
        setShow(true);
        SetIsDialogOpen(false);
      } else {
        setSnackbarMessage('Something went Wrong!!!!..');
        setIsloading(false);
        setShow(true);
        SetIsDialogOpen(false);
      }
    } catch (error) {
      setSnackbarMessage('Something went Wrong!!!!..');
      setShow(true);
      setIsloading(false);
      SetIsDialogOpen(false);
      throw new Error(error);
    }
  };

  const handleOnSubmit = (event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      handleDialogConfirmAction();
      setValidated(false);
    } else setValidated(true);
  };

  const dropZoneUpload = (dropzone) => {
    setDropZone({ event: dropzone });
  };

  const uploadFile = (type, value) => {
    if (type === 'resume' && fileValidation && document.getElementsByClassName('filepicker') && document.getElementsByClassName('filepicker').length && document.getElementsByClassName('filepicker')[0].className.includes('error-upload')) {
      document.getElementsByClassName('filepicker')[0].classList.remove('error-upload');
      setFileValidation(false);
    }
    handleOnchange(type, value);
  };

  return (
    <Modal
      style={{ borderRadius: 0, borderWidth: 0, border: 0, margin: 0 }}
      size="lg"
      className="modalDialog"
      show={isDialogOpen}
      onHide={() => {
        SetIsDialogOpen(false);
        setApplicantData(null);
      }}
    >
      <Form style={{ padding: '0px 10px' }} noValidate validated={validated} onSubmit={(event) => handleOnSubmit(event)}>
        <Modal.Header style={{ flex: 1, borderBottom: 0, marginBottom: '-2rem', position: 'relative' }}>
          <Modal.Title style={{ textAlign: 'center', flex: 1 }} className="d-flex justify-content-center">
            <h3 className="w-75">{applicantData && applicantData.jobTitle}</h3>
          </Modal.Title>
          <button type="button" className="modal-close-btn border-0" onClick={() => SetIsDialogOpen(false)}>
            <SvgClose style={{ height: 30, width: 28, stroke: '#ccc', strokeWidth: 2 }} />
          </button>
        </Modal.Header>
        <Modal.Body style={{ padding: '2rem 1rem 0px 1rem' }}>
          <div className="d-flex justify-content-center">
            <h6 className="text-center modal-descrip-text pb-3">Please enter basic information about yourselves and upload your resume for applying to this post. Feel free to tell a few words about yourselves using the message field.</h6>
          </div>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustom03" style={{ position: 'relative' }}>
              <Form.Label className="input-label">Name *</Form.Label>
              <Form.Control disabled={isloading} required type="text" onChange={(event) => handleOnchange('fullName', event.target.value)} name="fullName" value={applicantData && applicantData.fullName} placeholder="Your full name" />
              <Form.Control.Feedback type="invalid">Please fill out this field.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="12" md="6" controlId="validationCustom03" style={{ position: 'relative', paddingRight: '0.45rem', paddingLeft: '0.45rem' }}>
              <Form.Label className="input-label">Email *</Form.Label>
              <Form.Control disabled={isloading} required type="email" onChange={(event) => handleOnchange('email', event.target.value)} value={applicantData && applicantData.email} name="email" placeholder="example@gmail.com" />
              <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="12" md="6" controlId="validationCustom03" style={{ position: 'relative' }}>
              <Form.Label className="input-label">Phone *</Form.Label>
              <Form.Control disabled={isloading} required type="number" value={applicantData && applicantData.phone} onChange={(event) => handleOnchange('phone', event.target.value)} name="phone" placeholder="+91 999 999 9999" />
              <Form.Control.Feedback type="invalid">Please fill out this field.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm="12" md="12" controlId="exampleForm.ControlTextarea1" style={{ position: 'relative' }}>
              <Form.Label className="input-label">Message</Form.Label>
              <Form.Control disabled={isloading} className="careertextarea" as="textarea" onChange={(event) => handleOnchange('message', event.target.value)} name="message" value={applicantData && applicantData.message} placeholder="Message(optional)" rows="6" style={{ borderRadius: 0, opacity: 1, paddingTop: '2rem' }} />
              <Form.Control.Feedback type="invalid">Please fill out this field.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="12" sm="12" className="position-relative">
              <DropZone fileValidation={fileValidation} handleOnchange={uploadFile} dropZoneUpload={dropZoneUpload} />
              {fileValidation ? (
                <div className="position-absolute mb-0 invalid-feedback d-flex" style={{ bottom: '-1.5rem' }}>
                  Please upload a file
                </div>
              ) : null}
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 0, padding: '0.4rem 0.75rem 1rem 0.75rem' }}>
          <button type="submit" className={!isloading ? 'custom-button no-shadow' : 'btn-disabled'}>
            {isloading ? 'APPLYING...' : 'APPLY NOW'}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const LayoutAccordion = ({ applicantData, SetIsMoreDetailsBtnClicked, isMoreDetailsBtnClicked, careerDetails, SetIsDialogOpen, setApplicantData }) => {
  const onTitleClick = (jobId) => {
    setTimeout(() => {
      if (document.getElementById(jobId)) {
        document.getElementById(jobId).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 400);
  };

  return (
    <>
      <Accordion style={{ borderRadius: 0, paddingBottom: '1rem', position: 'relative' }}>
        {careerDetails &&
          careerDetails.map((item, index) => {
            return (
              <>
                <h4 key={item._id} style={{ color: '#222222', opacity: 1, fontWeight: 800, paddingBottom: '0.5rem', paddingTop: '2rem' }}>
                  {item.type}
                </h4>

                {item &&
                  item.jobs.map((job) => {
                    return (
                      <Card key={job._id} id={job._id} className="card-accordion" style={{ border: 0, marginBottom: job._id === 'job_2' ? 17 : 0, borderRadius: 0, display: 'flex', background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '3px 3px 6px rgba(0,0,0,0.1)', opacity: 1 }}>
                        <Card.Header style={{ marginBottom: 0 }}>
                          <Row noGutters className="d-flex justify-content-between align-items-center">
                            <h5 className="font-weight-normal" style={{ flex: 1 }}>
                              {job.designation}
                            </h5>

                            <Accordion.Toggle
                              className="moreDetailsOrapplyNowBtn custom-button no-shadow"
                              style={{ borderRadius: 0 }}
                              variant="primary"
                              eventKey={job._id}
                              onClick={() => {
                                SetIsMoreDetailsBtnClicked({ [job._id]: !isMoreDetailsBtnClicked[job._id] });
                                onTitleClick(job._id, index);
                              }}
                            >
                              {!isMoreDetailsBtnClicked[job._id] ? (
                                <p style={{ margin: 0 }}>
                                  <span className="btn-text d-none d-sm-inline-block d-md-inline-block">MORE</span>
                                  <span className="d-inline-block d-sm-none d-md-none">
                                    <SvgArrow style={{ stroke: 'white', fill: 'white', transitionDuration: '0.3s', transform: 'rotate(90deg)' }} />
                                  </span>
                                  <span className="btn-text d-none d-md-inline-block" style={{ paddingLeft: '0.3rem' }}>
                                    DETAILS
                                  </span>
                                </p>
                              ) : (
                                <p style={{ margin: 0 }}>
                                  <span className="btn-text d-none d-sm-inline-block d-md-inline-block">CLOSE</span>
                                  <span className="d-inline-block d-sm-none d-md-none">
                                    <SvgArrow style={{ stroke: 'white', transitionDuration: '0.3s', fill: 'white', transform: 'rotate(270deg)' }} />
                                  </span>
                                  <span className="btn-text d-none d-md-inline-block" style={{ paddingLeft: '0.3rem' }}>
                                    DETAILS
                                  </span>
                                </p>
                              )}
                            </Accordion.Toggle>
                          </Row>
                        </Card.Header>

                        <Accordion.Collapse eventKey={job._id}>
                          <Card.Body style={{ marginLeft: '0.8rem' }}>
                            <h6 className="py-1 font-weight-bold">Requirements</h6>
                            {job &&
                              job.skills.map((skill) => (
                                <div key={skill._id} className="d-inline-flex">
                                  <p>-&nbsp;</p>
                                  <p>{skill.description}</p>
                                </div>
                              ))}
                            <h6 className="py-1 pt-3 font-weight-bold">Job Locaton</h6>
                            <p>{job.jobLocation}</p>
                            <h6 className="py-1 pt-3 font-weight-bold">Experience</h6>
                            <p>{job.experince}</p>
                            <div className="text-right" style={{ paddingBottom: 10 }}>
                              <button
                                type="button"
                                style={{ borderRadius: 0 }}
                                className="moreDetailsOrapplyNowBtn custom-button no-shadow"
                                onClick={() => {
                                  SetIsDialogOpen(true);
                                  setApplicantData({ ...applicantData, jobTitle: job.designation });
                                }}
                              >
                                <p className="btn-text px-3" style={{ margin: 0 }}>
                                  APPLY
                                  <span className="btn-text d-none d-md-inline-block" style={{ paddingLeft: '0.3rem' }}>
                                    NOW
                                  </span>
                                </p>
                              </button>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    );
                  })}
              </>
            );
          })}
      </Accordion>
    </>
  );
};

const CareerPage = () => {
  const [isMoreDetailsBtnClicked, SetIsMoreDetailsBtnClicked] = React.useState({});
  const [isDialogOpen, SetIsDialogOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [applicantData, setApplicantData] = React.useState({});
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleOnchange = (type, value) => {
    setApplicantData({ ...applicantData, [type]: value });
  };

  return (
    <Layout pageInfo={{ pageName: 'careers' }} noLayoutbanner noUpperFooter>
      <SEO title="Careers" />
      <Container fluid style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Container style={{ opacity: 1 }}>
          <Row className="mt-5 mt-lg-0 flex-column-reverse flex-md-row">
            <Col xs="12" md="6" className="d-flex justify-content-center" style={{ flexDirection: 'column' }}>
              <h1>Join Our Team</h1>
              <h6>Are you a problem solver? Passionate about building digital products? We would love to invite you to apply for our vacancies below.</h6>
            </Col>
            <Col xs="12" md="6">
              <div className="d-flex justify-content-center justify-content-md-end">
                <SvgTeam />
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid style={{ background: '#F8FAFF', width: '100%', opacity: 1, padding: '1rem 0rem 2rem 0rem', maxWidth: '100%' }}>
          <Container>
            <LayoutAccordion setApplicantData={setApplicantData} applicantData={applicantData} SetIsDialogOpen={SetIsDialogOpen} isMoreDetailsBtnClicked={isMoreDetailsBtnClicked} SetIsMoreDetailsBtnClicked={SetIsMoreDetailsBtnClicked} careerDetails={careerDetailsArray} />
          </Container>
        </Container>
        {show ? <AlertNotification show={show} setShow={setShow} snackbarMessage={snackbarMessage} /> : null}
        {isDialogOpen ? <DialogBox setSnackbarMessage={setSnackbarMessage} setApplicantData={setApplicantData} handleOnchange={handleOnchange} applicantData={applicantData} setShow={setShow} isDialogOpen={isDialogOpen} SetIsDialogOpen={SetIsDialogOpen} /> : null}
      </Container>
    </Layout>
  );
};

export default CareerPage;
