const careerDetailsArray = [
  {
    type: 'Design',

    jobs: [
      {
        _id: 'job_1',
        designation: 'UI/UX Designer',
        jobLocation: 'Kakkanad, Cochin',
        skills: [
          { _id: 'job_1_skill_1', description: 'Gather and evaluate user requirements in collaboration with product managers and engineers' },
          { _id: 'job_1_skill_2', description: 'Develop UI mockups and prototypes.' },
          { _id: 'job_1_skill_3', description: 'Identify and troubleshoot UX problems (e.g. responsiveness)' },
          { _id: 'job_1_skill_4', description: `Excellent understanding of user-experience design for mobile understanding of Apple's human interface` },
          { _id: 'job_1_skill_5', description: 'Design guidelines and Android design,including Google’s material design' },
        ],
        experince: '2-3 Years',
      },
    ],
  },
  {
    type: 'Development',

    jobs: [
      {
        _id: 'job_2',
        designation: 'React & React Native JS Frontend Developer',
        jobLocation: 'Kakkanad, Cochin',
        skills: [
          { _id: 'job_2_skill_1', description: 'Knowledge of Object-Oriented Programming.' },
          { _id: 'job_2_skill_2', description: 'Complete familiarity with Javascript and capability to write script that is optimised and works perfectly.' },
          { _id: 'job_2_skill_3', description: 'Capability to write Well-documented code with Javascript that is easily readable' },
          { _id: 'job_2_skill_4', description: 'Having the knowledge of third-party dependencies and debugging dependency conflicts that might occur.' },
          { _id: 'job_2_skill_5', description: 'Understanding of native build tools like Xcode, Gradle etc.' },
          { _id: 'job_2_skill_6', description: 'Experience in testing with tools like Jest, Detox, Cypress etc.' },
          { _id: 'job_2_skill_7', description: 'Knowledge about Offline Storage, REST APIs, and document request model.' },
        ],
        experince: '2-3 Years',
      },
      {
        _id: 'job_3',
        designation: 'Node JS Backend Developer',
        jobLocation: 'Kakkanad, Cochin',
        skills: [
          { _id: 'job_3_skill_1', description: 'Strong proficiency with JavaScript' },
          { _id: 'job_3_skill_2', description: 'Good knowledge in MongoDB' },
          { _id: 'job_3_skill_3', description: 'Knowledge of Node.js and frameworks available for it such as Express,StrongLoop' },
          { _id: 'job_3_skill_4', description: 'Understanding the nature of asynchronous programming and its quirks and workarounds' },
          { _id: 'job_3_skill_5', description: 'Good understanding of server-side templating languages' },
          { _id: 'job_3_skill_6', description: 'Good understanding of server-side CSS preprocessors such as SAAS' },
          { _id: 'job_3_skill_7', description: 'Basic understanding of front-end technologies, such as HTML5, and CSS3' },
          { _id: 'job_3_skill_8', description: 'Understanding accessibility and security compliance' },
          { _id: 'job_3_skill_9', description: 'User authentication and authorization between multiple systems, servers, and environments' },
          { _id: 'job_3_skill_10', description: 'Integration of multiple data sources and databases into one system' },
          { _id: 'job_3_skill_11', description: 'Understanding fundamental design principles behind a scalable application' },
          { _id: 'job_3_skill_12', description: 'Understanding differences between multiple delivery platforms, such as mobile vs. desktop, and optimizing output to match the specific platform' },
          { _id: 'job_3_skill_13', description: 'Creating database schemas that represent and support business processes' },
          { _id: 'job_3_skill_14', description: 'Implementing automated testing platforms and unit tests' },
          { _id: 'job_3_skill_15', description: 'Proficient understanding of code versioning tools, such as Git' },
        ],
        experince: '2-3 Years',
      },
    ],
  },

  {
    type: 'Content',

    jobs: [
      {
        _id: 'job_4',
        designation: 'Blog and Social Media Content writer',
        jobLocation: 'Kakkanad,Cochin',
        skills: [
          { _id: 'job_4_skill_1', description: 'Have experience writing tech based content.' },
          { _id: 'job_4_skill_2', description: 'Proven work experience as a Content Writer, Copywriter or similar role' },
          { _id: 'job_4_skill_3', description: 'Portfolio of published articles' },
          { _id: 'job_4_skill_4', description: 'Experience doing research using multiple sources' },
          { _id: 'job_4_skill_5', description: 'Familiarity with web publications' },
          { _id: 'job_4_skill_6', description: 'Excellent writing and editing skills in English' },
          { _id: 'job_4_skill_7', description: 'Hands-on experience with Content Management Systems (e.g. WordPress)' },
          { _id: 'job_4_skill_8', description: 'Ability to meet deadlines' },
          { _id: 'job_4_skill_9', description: 'BSc in Marketing, English, Journalism or related field' },
        ],
        experince: '1-2 Years',
      },
    ],
  },
];

export default careerDetailsArray;
